import React from "react"
import { graphql } from "gatsby"
import Header from "../../components/header/header"
import Footer from "../../components/footer/footer"
import { Container, Row, Col, Card, CardTitle, NavLink } from "reactstrap"
import styles from "../../pages/css/index.module.css"


export default ({data}) => {
    const info = data.craft.content[0];
    return (
        <div>
            <Header/>
            <div className={styles.interiorMainContent}>
                <Container>
                    <Row>
                        <Col xs="12">
                            {info.mainContent ? <div dangerouslySetInnerHTML={{__html: info.mainContent }}></div> : ''}
                            {info.documentSiteLinks ? info.documentSiteLinks.map((siteLink, index) => 
                                siteLink.externalSiteLink !== undefined &&
                                    <a key={index} href={siteLink.externalSiteLink} target="_blank" rel="noopener noreferrer" className={styles.documentSiteLinks}>{siteLink.externalSiteLinkName}</a>
                            ) : ''}
                            {info.documentSiteLinks ? info.documentSiteLinks.map((siteLink, index) => 
                                siteLink.internalSiteLink !== undefined &&
                                <a key={index} href={`${siteLink.internalSiteLink[0].slug}`} rel="noopener noreferrer" className={styles.documentSiteLinks}>{siteLink.internalSiteLinkName}</a>
                            ) : ''}
                            {info.documentSiteLinks ? info.documentSiteLinks.map((pdf, index) => 
                                pdf.pdfDocument !== undefined &&
                                <a key={index} href={pdf.pdfDocument[0].url} target="_blank" rel="noopener noreferrer" className={styles.documentSiteLinks}>{pdf.pdfName}</a>
                            ): ''}
                        </Col>
                    </Row>
                </Container>
                <div className={styles.cardSection}>
                    <Container>
                        <Row>
                            {info.bppPdfDocuments ? info.bppPdfDocuments.map((document, index) => 
                                <Col xs="12" md="4" key={index}>
                                    <Card body className={styles.cardShadow}>
                                        <CardTitle>{document.documentTitle}</CardTitle>
                                        <NavLink className={styles.cardButton} href={document.document[0].url} target="_blank">Open PDF</NavLink>
                                    </Card>
                                </Col>
                            ) : null}
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export const query = graphql`
  query ($slug: [String]) {
    craft {
      content: entries(siteId: "4", slug: $slug, status: ["disabled", "enabled"]) {
        id
        title
        ... on craft_mapAndResellerPolicies_minimumAdvertisedPricePolicy_Entry {
          id
          mainContent 
          documentSiteLinks {
            ... on craft_documentSiteLinks_pdf_BlockType {
              id
              pdfDocument {
                url
              }
              pdfName
            }
            ... on craft_documentSiteLinks_internalSiteLink_BlockType {
              id
              internalSiteLink (status: ["disabled", "enabled"]){
                slug
              }
              internalSiteLinkName
            }
            ... on craft_documentSiteLinks_externalSiteLink_BlockType {
              id
              externalSiteLink
              externalSiteLinkName
            }
          }
          bppPdfDocuments {
            ... on craft_bppPdfDocuments_bppPdfDocument_BlockType {
              id
              documentTitle
              enabled
              document {
                url
              }
            }
          }
        }
      }
    }
  }
`;